import React, {useEffect, useState} from 'react';
import {Col, Row, Segmented, Select, Affix, message} from "antd";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './index.less';
import store from "store";
import {queryLatestTenList} from "../../../../config/api";


/**
 *
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t} = useTranslation()
    const {state: {name, levelList}} = useLocation()
    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        console.log('params', levelList)
        handleQueryJackpotLatestTen({levelList:levelList})
    }, [])

    const handleQueryJackpotLatestTen = async params => {
        let ret = await queryLatestTenList(params)
        console.log(ret)
        if (ret.success) {
            setDataSource(ret.result)
        } else {
            message.error('接口請求異常', 5)
        }
    }

    const bgImage = require("../../../../public/assets/detail/BG"+name+".png")
    const frameBgImage = require("../../../../public/assets/detail/Frame"+name+".png")

    return (<div className={'detail-container'}
                 style={{backgroundImage:`url(${bgImage})`}}>
            <div className="data-view">
                <div className={'data-wrapper'}
                     style={{backgroundImage:`url(${frameBgImage})`}}>
                    <div className={'data-parent'}>
                        <div className={'data-area'}>
                            <Row className={'title'}>
                                <Col span={24}>{t('detail.10001')}</Col>
                            </Row>
                            <Row className={'tb-header'}>
                                <Col span={10} offset={3}>{t('detail.10002')}</Col>
                                <Col span={11} style={{textAlign:'right'}}>{t('detail.10003')}</Col>
                            </Row>
                            {dataSource.map((data, index) => (
                                <Row className={'tb-body'} key={index}>
                                    <Col span={2} className={'no'}>{ index + 1}</Col>
                                    <Col span={10} offset={1} className={'date'}>{data.timestamp!=null&&data.timestamp!=''?data.timestamp.substring(0, 10):''}</Col>
                                    <Col span={11} className={'amount'}>{data.displayAmount!=null&&data.displayAmount!=''?data.displayAmount.substring(3):''}</Col>
                                </Row>
                            ))}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )

}


