import React from 'react';
import Router from './router'
import reportWebVitals from './reportWebVitals';
import * as ReactDOMClient from 'react-dom/client';
// import 'default-passive-events'
import './index.css';


const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(

    <Router />


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

