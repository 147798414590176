import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space} from "antd";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import {queryDashboardData} from '../../../../config/api/index'
import './index.less';
import store from "store";


/**
 * Dashboard
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [count, setCount] = useState(107076408);
    const [list, setList] = useState([])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCount(count => count + 9*9);
            console.log('count', count)
            handleQueryDashboardData()
        }, 1000);

        return () => clearInterval(intervalId);
    }, [])

    const handleQueryDashboardData = async () => {
        let retData = await queryDashboardData()
        if (retData['success']) {
            //generate dynamic data for demo
            // let retDataList = retData['result'].map((item,index)=>{
            //     item['amount'] +=Math.floor(Math.random()*10 +1)
            //     return item
            // })
            // console.log('dd', retDataList)
            setList(retData['result'])
        }
    }

    const handleToSlot = () => {
        navigate('/web/slot', {})
    }

    return (<div className={'web-dashboard-container'}>
            <div className={'dashboard-content'}>
                <Row>
                    <img className={'dashboard-title-img'}
                         src={require('../../../../public/assets/main/home-title.png')} alt={'home-title'}/>
                </Row>
                <Row className={'slot-content'}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        {
                            list && list.map((item,index)=>
                                <Row className={'card-bg'} key={index}>
                                    <Col span={10} className={'logo-wrapper'}>
                                        <img className={'logo'}
                                             src={require('../../../../public/assets/logo/'+item['name'].toUpperCase()+'-logo.png')} alt={'jackpot logo'}/>
                                    </Col>
                                    <Col span={14} className={'amount'}>
                                        <div className={'data-wrapper'}>
                                            <div className={'type'}>{item['type'].toUpperCase() === 'GRAND' ? t('tag.10003') :t('tag.10004')}</div>
                                            <div className={'value'}>
                                                <span>$</span>
                                                <Odometer value={(item['amount']/100+0.001).toFixed(3)} duration={8000} format={'(,ddd).ddd'} animation={'slot'}
                                                          theme={'default'}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)
                        }
                    </Space>
                    <Row className={'btn-wrapper'}>
                        <Col span={24}>
                            <Button onClick={handleToSlot} className={'btn'}>{t('dashboard.10001')}</Button></Col>
                    </Row>
                </Row>
            </div>
        </div>
    )

}


