import React, {useEffect, useState} from 'react';
import {Col, Row} from 'antd'
import {useTranslation} from "react-i18next";
import './index.less'
import Odometer from "react-odometerjs";

export default function Index(props) {

    const {t, i18n} = useTranslation()

    const {name, displayAmount, timestamp} = props.data

    return (
            <Col span={12} className={'web-his-item-container'} >
                <Row className="content">
                    <Col span={2} className={'no'}>{props.itemId}</Col>
                    <Col span={8} className={'logo-wrapper'}>
                        <img src={require('../../../public/assets/web/logo/' + name + '-logo.png')} alt={1}/>
                    </Col>
                    <Col span={12} className={'info-wrapper'}>
                        <Row >
                            <Col span={24} className={'amount'}>{displayAmount != null && displayAmount !== '' ? displayAmount.substring(3) : ''}</Col>
                            <Col span={24} className={'date'}>{timestamp != null && timestamp !== '' ? timestamp.substring(0, 10) : ''}</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
    )
}


