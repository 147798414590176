import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import {queryDashboardData, wynnRewardsAppAction} from '../../../../config/api/index'
import Utils from '../../../../src/util'
import './index.less';
import Pubsub from "pubsub-js";


/**
 * Dashboard
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [jackpotRenderList, setJackpotRenderList] = useState([])
    const [list, setList] = useState([])
    const [timerArr, setTimerArr] = useState([])

    const [lineNo, setLineNo] = useState(0);
    const CryptoJS = require('crypto-js');
    const SUB_EVENTS = Utils.SUB_EVENTS
    useEffect(() => {
        localStorage.removeItem('das_jackpotList')
        localStorage.removeItem('das_orgJackpotListHistory')

        setJackpotRenderList([])
        handleQueryDashboardData()

        const intervalId = setInterval(() => {
            handleQueryDashboardData()
        }, 60000);

        return () => clearInterval(intervalId);
    }, [])
    const clearTimer = () => {
        for (const timeoutID of timerArr) {
            clearTimeout(timeoutID)
            console.log('clearTime',timeoutID)
        }

        setTimerArr([])
    }
    useEffect(() => {
        Pubsub.subscribe(SUB_EVENTS.M_DASH, (event, data) => {
            executeRender(data)
        })
    }, [])
    const executeRender = renderDataList => {
        clearTimer()
        let timerList = []
        for (let index = 0; index < Utils.ODOMETER_SLICE_STEP.length; index++) {

            const cTimer = setTimeout(() => {
                console.log('das-index', index)
                console.log('renderDataList[index]', renderDataList[index])
                setList(renderDataList[index])
            }, index * 1000)
            timerList.push(cTimer)
        }
        setTimerArr(timerList)
    }

    const handleQueryDashboardData = async params => {
        let retData = await queryDashboardData(params)
        if (retData && retData['success']) {

            let retDataList = retData['result']
            //push to array and save
            let orgJackpotListHistory = JSON.parse(localStorage.getItem('das_orgJackpotListHistory')) || []

            let localJackpotList = localStorage.getItem('das_jackpotList')
            console.log('last===curr', CryptoJS.SHA256(localJackpotList).toString(), CryptoJS.SHA256(JSON.stringify(retDataList)).toString())
            if (!localJackpotList
                || CryptoJS.SHA256(localJackpotList).toString() !== CryptoJS.SHA256(JSON.stringify(retDataList)).toString()) {
                localStorage.setItem('das_jackpotList', JSON.stringify(retDataList))
                localStorage.setItem('das_lastJackpotList', JSON.stringify(retDataList))
            } else if (localJackpotList && CryptoJS.SHA256(localJackpotList).toString()
                === CryptoJS.SHA256(JSON.stringify(retDataList)).toString()) {
                return
            }

            let renderArr = []
            let jackpotList = localStorage.getItem('das_jackpotList')
            for (let stepNumIdx in Utils.ODOMETER_SLICE_STEP) {
                let stepNum = Utils.ODOMETER_SLICE_STEP[stepNumIdx]
                let currJackpotList = jackpotList && JSON.parse(jackpotList)

                let newDataList = []
                for (let itemDataIdx = 0; itemDataIdx < currJackpotList.length; itemDataIdx++) {
                    let itemData = currJackpotList[itemDataIdx]

                    let amount = itemData['amount']
                    if(orgJackpotListHistory.length>0){
                        // history data exist
                        let itemLastAmount = orgJackpotListHistory[orgJackpotListHistory.length-1][itemDataIdx]['amount']
                        //if currAmount === last amount,fz directly
                        if(itemData['amount'] == itemLastAmount){
                            itemData['amount'] = itemLastAmount
                        } else {
                            let portion = (amount -itemLastAmount) / 60
                            itemData['amount'] -= (60-stepNumIdx) * portion
                            // if current amount minus stepNum less than lastAmount
                            // then from last amount
                            /*if (amount - stepNum > itemData['baseValue']) {
                                let tmpAmount = itemData['amount'] - stepNum
                                if(tmpAmount < itemLastAmount){
                                    itemData['amount'] = itemLastAmount
                                } else {
                                    itemData['amount'] -= stepNum
                                }
                            } else {
                                itemData['amount'] = itemData['baseValue']
                            }*/
                        }
                    } else {
                        //not exsit history data
                        if (amount - stepNum > itemData['baseValue']) {
                            itemData['amount'] -= stepNum
                        } else {
                            itemData['amount'] = itemData['baseValue']
                        }
                    }
                    newDataList.push(itemData)
                }
                renderArr.push(newDataList)
            }

            if (renderArr.length > 0) {
                orgJackpotListHistory.push(retDataList)
                localStorage.setItem('das_orgJackpotListHistory', JSON.stringify(orgJackpotListHistory))
                clearTimer()

                Pubsub.publish(SUB_EVENTS.M_DASH, [...renderArr])

                localStorage.setItem('das_renderList', JSON.stringify(renderArr))
            }
        }
    }

    const handleToSlot = () => {
        Utils.checkIsGcm() && wynnRewardsAppAction({
            "wynnId": Utils.getWynnId(),
            "triggerTime": new Date(+new Date()+8*3600*1000).toISOString(),
            "pageVisited": Utils.ACTION_CAPTURE.SJ_LIVE[0],
            "action": Utils.ACTION_CAPTURE.SJ_LIVE[1],
            "parameter": '',
            "itemId": ''
        })
        clearTimer()
        navigate('/mobile/4C63008D08E93F5A53FD02196488FCA5', {})
    }

    return (<div className={'dashboard-container'}>
            <div className={'dashboard-content'}>
                <Row>
                    <img className={'dashboard-title-img'}
                         src={require('../../../../public/assets/main/home-title.png')} alt={'home-title'}/>
                </Row>
                <Row className={'slot-content'}>
                    <Space direction="vertical" size="small" style={{display: 'flex',width:'100%'}}>
                        {
                            list && list.map((item,index)=>
                                <Row className={'card-bg'} key={index}>
                                    <Col span={10} className={'logo-wrapper'}>
                                        <img className={'logo'}
                                             src={require('../../../../public/assets/logo/'+item['name'].toUpperCase()+'-logo.png')} alt={'jackpot logo'}/>
                                    </Col>
                                    <Col span={14} className={'amount'}>
                                        <div className={'data-wrapper'}>
                                            <div className={'type'}>{item['type'].toUpperCase() === 'GRAND' ? t('tag.10003') :t('tag.10004')}</div>
                                            <div className={'value'}>
                                                <span>$</span>
                                                <Odometer value={(item['amount']/100+0.001).toFixed(3)} duration={Utils.ODOMETER_DURATION} format={'(,ddd).ddd'}
                                                          theme={'default'} animation={'slot'}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>)
                        }
                    </Space>
                    <Row className={'btn-wrapper'}>
                        <Col span={24}>
                            <Button onClick={handleToSlot} className={'btn'}>{t('dashboard.10001')}</Button></Col>
                    </Row>
                </Row>
            </div>
        </div>
    )

}


