const env = ''


const devConf = {
    baseApiUrl: 'http://localhost:8011',
    proxyProtocol: 'http',
    proxyHost: 'http://localhost',
    proxyPort: 8090,
}

const prodConf = {
    baseApiUrl: '',
    proxyProtocol: 'https',
    proxyHost: 'https://wpmqwcosappv01.wpm.maclab.wynnmacau.com:9001',
    proxyPort: 9001,
}


export function getConfiguration() {
    let conf = {
        baseApiUrl: '/api/jackpot',
        proxyProtocol: '',
        proxyHost: '',
        proxyPort: 8090,
    }
    switch (env) {
        case 'dev':
            conf = devConf
            break;
        case 'prod':
            conf = prodConf
            break;
    }

    return conf
}

export function getBaseApiUrl() {
    return getConfiguration().baseApiUrl
}
export function getProxyProtocol() {
    return getConfiguration().proxyProtocol
}

export function getProxyHost() {
    return getConfiguration().proxyHost

}

export function getProxyPort() {
    return getConfiguration().proxyPort

}
