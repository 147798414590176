import store from 'store'

export default {
    ODOMETER_DURATION: 2000,
    ODOMETER_INIT_LINENO: 30,
    ODOMETER_SLICE_STEP: [
        2000, 1972, 1943, 1911, 1884,
        1853, 1822, 1793, 1761, 1734,
        1704, 1672, 1643, 1611, 1584,
        1554, 1523, 1493, 1461, 1434,
        1403, 1372, 1343, 1311, 1284,
        1251, 1222, 1193, 1161, 1034,
        1009, 972, 943, 911, 885,
        858, 822, 793, 661, 634,
        604, 572, 446, 415, 384,
        354, 325, 296, 261, 232,
        200, 171, 141, 119, 91,
        60, 41, 25, 10, 0
    ],
    ACTION_CAPTURE:{
        SJ_LIVE:['Slot Jackpots', 'Open'],
        SJ_MIX_HISTORY:['SJ-MIX-History','Click'],
        SJ_SINGLE_HISTORY:['SJ-Single-History','Click']
        // SJ_DASH:['Slot Jackpots', 'Open']
    },
    SUB_EVENTS:{
        M_DASH: 'M_DASH',
        M_SLOT: 'M_SLOT',
        W_DASH: 'W_DASH',
        W_SLOT: 'W_SLOT'
    },
    getLocalSearchParams() {
        return store.get('searchParams') || {}
    },
    getWynnId() {
        const searchParams = this.getLocalSearchParams()

        return searchParams['wynnId'] || ''
    },
    getSearchParams() {
        const query = window.location.search.substring(1);
        const vars = query.split("&");

        let paramObj = {}
        for (let i = 0; i < vars.length; i++) {

            let pair = vars[i].split("=")
            if (!pair[0]) continue
            paramObj[pair[0]] = pair[1]

        }

        return paramObj;
    },
    getKioskName() {
        const searchParams = this.getLocalSearchParams()
        if(!searchParams['kioskName']){
            return ''
        }

        return searchParams['kioskName']
    },
    checkIsGcm(){
        return !(this.getKioskName().toUpperCase()==='WPGCM' || this.getKioskName().toUpperCase()==='WMGCM')
    },
    formatToThousands(num) {
        if (!num) return 0
        const str = num && num.toString()
        return str.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
    sleep1(sleepTime){
        return  setTimeout(()=>{},sleepTime);
    },
    sleep(sleepTime){
        return new Promise(resolve=>{
            setTimeout(resolve,sleepTime);
        });
    },
    createWrapper () {

        let executeCancel = false

        function start () {
            executeCancel = false
        }
        function cancel () {
            return Promise.reject(new Error())
        }

        function executorWrapper (fn, ...args) {
            if (executeCancel) {
                return Promise.reject(new Error())
            }
            return fn(...args)
        }

        return { executorWrapper, start, cancel }
    }
}

