import axios from 'axios'
import * as config from './config.js'
import Utils from "../src/util";
import kioskHelper from "../src/kioskHelper";

let urlParams = Utils.getSearchParams();
if (JSON.stringify(urlParams) !== '{}') {
    localStorage.setItem('searchParams', JSON.stringify(urlParams))
} else {
    let defaultParam = {'xLang': 'EN'}
    localStorage.setItem('searchParams', JSON.stringify(defaultParam))
}

let searchParams = localStorage.getItem('searchParams') || '{}'

if (!searchParams || searchParams === '' || searchParams === '{}') {
    kioskHelper.write("Search Params Invalid")
    throw new Error("Search Params Invalid")
}

searchParams = JSON.parse(searchParams)
const {xSid, xLang, xChannel, ipAddress, kioskName} = searchParams

const service = axios.create({
    baseURL: config.getBaseApiUrl(),
    timeout: 60000,
    // proxy: {
    //     host: config.getProxyHost(),
    //     port: config.getProxyPort()
    // }
})


service.defaults.headers = {
    'x-sid': xSid || '',
    'x-lang': xLang || '',
    'x-channel': xChannel || '',
    'ip-address': ipAddress || '',
    'kiosk_name': kioskName || ''
}

service.interceptors.request.use(config => {

    return config
}, err => {
    return error;
})

service.interceptors.response.use(response => {
    if (response.status) {
        switch (response.status) {
            case 200:
                kioskHelper.write('[RESPONSE]'+(response.data ?JSON.stringify(response.data):''))
                return response.data;
            default:
                kioskHelper.write('[RESPONSE-EXCEPTION]'+'RESPONSE STATUS:'+response.status)
        }
    } else {
        return response;
    }
}, err => {
    if (err.response) {
        switch (err.response.status) {
            case 400:
                console.log(err.response.statusText)
                break;
            case 401:
                kioskHelper.notify(kioskHelper.ACTION_UNANTHORIZED)
                break;
            case 500:
            case 502:
                console.log('http status code:', err.response.status)
                break;
            case 504:
                console.log('http status code:', err.response.status)
                break;
            default:
                return Promise.reject(err)
        }
    } else {
        return Promise.reject(err)
    }
})

let http = {
    service:service,
    post: {},
    get: {},
    put: {},
    delete: {}
}

function getLogPath(api){
    return window.location.origin+config.getBaseApiUrl()+api+window.location.search
}

http.post = function (api, data = {}) {
    kioskHelper.write('POST-'+getLogPath(api)+' - '+(data ?JSON.stringify(data):''))
    return service.post(api, data)
};

http.get = function (api, param = {}) {
    kioskHelper.write('GET-'+getLogPath(api)+' - '+(param ?JSON.stringify(param):''))

    return service.get(api, { params: param })
};

http.put = function (api, data = {}) {
    kioskHelper.write('PUT - '+getLogPath(api)+' - '+(data ?JSON.stringify(data):''))

    return service.put(api,  data)
};

http.delete = function (api, data = {}) {
    kioskHelper.write('DELETE - '+getLogPath(api)+' - '+(data ?JSON.stringify(data):''))

    return service.delete(api, {data})
};

export default http
