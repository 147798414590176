import './App.css';
import React, {useEffect} from 'react';
import {ConfigProvider} from 'antd';
import 'antd/dist/antd.css';
import '../config/i18n'
import zhCN from 'antd/es/locale/zh_CN';
import zhTW from 'antd/es/locale/zh_TW';
import en from 'antd/es/locale/en_US';

export default function App(props) {

    let searchParams = localStorage.getItem('searchParams') || ''
    searchParams = searchParams && JSON.parse(searchParams)
    let antLang;
    let currentLang = searchParams['xLang'] ? searchParams['xLang'] : 'TC'

    switch (currentLang.toUpperCase()){
        case 'TC':
            antLang = zhTW
            break;
        case 'EN':
            antLang = en
            break;
        case 'SC':
            antLang = zhCN
            break;
    }

    useEffect(()=>{
        document.addEventListener('touchstart',function (event) {
            if(event.touches.length>1){
                event.preventDefault();
            }
        }, {passive: false})

        document.addEventListener('touchmove',function (event) {

            if(event.touches.length>=2){
                event.preventDefault();
            }
        })

        let lastTouchEnd=0;
        document.addEventListener('touchend',function (event) {
            let now=(new Date()).getTime();
            if(now-lastTouchEnd<=300){
                event.preventDefault();
            }
            lastTouchEnd=now;
        },false)

        window.addEventListener('mousewheel', function(event){
            if (event.ctrlKey === true || event.metaKey) {
                event.preventDefault();
            }
        },{ passive: false});
    })

    return <ConfigProvider locale={antLang}>
        {props.children}
    </ConfigProvider>
}
