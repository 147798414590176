import axios from 'axios'
import * as config from './config.js'
import Utils from "../src/util";
import kioskHelper from "../src/kioskHelper";

if (window.location.pathname === '/index' || window.location.pathname === '/') {
    let urlParams = Utils.getSearchParams();
    if (urlParams['showType']) {
        localStorage.removeItem('appliedPriceFilterData')
    }
    if (JSON.stringify(urlParams) !== '{}') {
        localStorage.setItem('searchParams', JSON.stringify(urlParams))
    }
}

let searchParams = localStorage.getItem('searchParams') || '{}'

if (!searchParams || searchParams === '' || searchParams === '{}') {
    kioskHelper.write("Search Params Invalid")
    throw new Error("Search Params Invalid")
}

searchParams = JSON.parse(searchParams)
const {xSid, xLang, xChannel, ipAddress, kioskName, wynnId} = searchParams

const service = axios.create({
    baseURL:'',
    timeout: 60000,
    // proxy: {
    //     host: config.getProxyHost(),
    //     port: config.getProxyPort()
    // }
})


service.defaults.headers = {
    'x-sid': xSid || '',
    'x-lang': xLang || '',
    'x-channel': xChannel || '',
    'ip-address': ipAddress || '',
    'kiosk_name': kioskName || 'Mobile',
    'machineName': kioskName || 'Mobile',
}

service.interceptors.request.use(config => {

    return config
}, err => {
    return error;
})

service.interceptors.response.use(response => {
    if (response.status) {
        switch (response.status) {
            case 200:
                kioskHelper.write('[RESPONSE]'+(response.data ?JSON.stringify(response.data):''))
                return response.data;
            default:
                kioskHelper.write('[RESPONSE-EXCEPTION]'+'RESPONSE STATUS:'+response.status)
        }
    } else {
        return response;
    }
}, err => {
    if (err.response) {
        switch (err.response.status) {
            case 400:
                console.log(err.response.statusText)
                break;
            case 401:
                kioskHelper.notify(kioskHelper.ACTION_UNANTHORIZED)
                break;
            case 500:
            case 502:
                console.log('http status code:', err.response.status)
                break;
            case 504:
                console.log('http status code:', err.response.status)
                break;
            default:
                return Promise.reject(err)
        }
    } else {
        return Promise.reject(err)
    }
})

let axiosServiceWithoutPrefix = {
    service:service,
    post: {},
    get: {},
    put: {},
    delete: {}
}


axiosServiceWithoutPrefix.post = function (api, data = {}) {
    return service.post(api, data)
};

axiosServiceWithoutPrefix.get = function (api, param = {}) {

    return service.get(api, { params: param })
};

axiosServiceWithoutPrefix.put = function (api, data = {}) {

    return service.put(api,  data)
};

axiosServiceWithoutPrefix.delete = function (api, data = {}) {

    return service.delete(api, {data})
};

export default axiosServiceWithoutPrefix
