/**
 * kiosk helper
 */

export default {
    ACTION_TAP: 'tap',
    ACTION_LOG: 'log',
    ACTION_LOGIN: 'login',
    ACTION_UNANTHORIZED: 'unanthorized',
    ACTION_DASHBOARD: 'dashboard',
    write(logStr) {
        this.notify(this.ACTION_LOG, logStr)
    },
    notify(action, paramStr) {
        try {
            // console.log('notify action:' + action)
            // window.external.notify('jackpot.' + action + (paramStr || ''))
            window.chrome.webview.postMessage('jackpot.' + action + (paramStr || ''))
        } catch (error) {
            // console.log('Action not in kiosk:', action)
        }
    },
    navigate(path) {
        window.location.pathname = path
    }
}
