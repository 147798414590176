import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import App from "./App";
import About from "./views/about";

import {MobileDash, MobileSlot, MobileDetail, MobileHistory} from "./views/mobile"
import {WebHistory, WebSlot, WebDashboard, WebDetail} from "./views/web/"


export default class IRouter extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <App>
                    <Routes>
                        {/*<Route index element={<MobileSlot/>}/>*/}
                        <Route path="mobile">
                            {/*<Route index element={<MobileSlot/>}/>*/}
                            <Route path="DC7161BE3DBF2250C8954E560CC35060" element={<MobileDash/>}/>
                            <Route path="4C63008D08E93F5A53FD02196488FCA5" element={<MobileSlot/>}/>
                            <Route path="detail" element={<MobileDetail/>}/>
                            <Route path="3CD15F8F2940AFF879DF34DF4E5C2CD1" element={<MobileHistory/>}/>
                            <Route path="about" element={<About/>}/>
                        </Route>
                        <Route path="web">
                            {/*<Route index element={<MobileSlot/>}/>*/}
                            <Route path="DC7161BE3DBF2250C8954E560CC35060" element={<WebDashboard/>}/>
                            <Route path="4C63008D08E93F5A53FD02196488FCA5" element={<WebSlot/>}/>
                            <Route path="detail" element={<WebDetail/>}/>
                            <Route path="3CD15F8F2940AFF879DF34DF4E5C2CD1" element={<WebHistory/>}/>
                        </Route>
                    </Routes>
                </App>
            </BrowserRouter>
        );
    }
}
