import React, {useEffect, useState} from 'react';
import {Affix, Col, Row, Segmented, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Jackpot from '../../../components/Jackpot'
import {querySlotData, wynnRewardsAppAction} from '../../../../config/api/index'
import Utils from '../../../../src/util'
import Pubsub from 'pubsub-js'
import {Divider} from "antd/es";
import './index.less';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [jackpotRenderListWM, setJackpotRenderListWM] = useState([])
    const [jackpotRenderListWP, setJackpotRenderListWP] = useState([])
    const [segmentValue, setSegmentValue] = useState('WM');
    const [lineNo, setLineNo] = useState(0);
    const [timerArr, setTimerArr] = useState([])

    const CryptoJS = require('crypto-js');
    const SUB_EVENTS = Utils.SUB_EVENTS

    useEffect(() => {
        localStorage.removeItem('jackpotListWM')
        localStorage.removeItem('jackpotListWP')
        localStorage.removeItem('orgJackpotListHistoryWM')
        localStorage.removeItem('orgJackpotListHistoryWP')
        setJackpotRenderListWM([])
        setJackpotRenderListWP([])
        handleQuerySlotData()

        const intervalId = setInterval(() => {
            handleQuerySlotData()
        }, 60000);

        return () => clearInterval(intervalId);
    }, [])

    const executeRender = renderDataList => {
        clearTimer()
        let timerList = []
        for (let index = 0; index < Utils.ODOMETER_SLICE_STEP.length; index++) {

            const cTimer = setTimeout(() => {
                console.log('index', index)
                if (renderDataList['WM'][index] && renderDataList['WM'][index].length>0){
                    setJackpotRenderListWM(renderDataList['WM'][index])
                }
                if (renderDataList['WP'][index] && renderDataList['WP'][index].length>0){
                    setJackpotRenderListWP(renderDataList['WP'][index])
                }
            }, index * 1000)

            timerList.push(cTimer)
        }
        setTimerArr(timerList)
    }

    const clearTimer = () => {
        for (const timeoutID of timerArr) {
            clearTimeout(timeoutID)
            console.log('clearTime',timeoutID)
        }

        setTimerArr([])
    }

    useEffect(() => {
        Pubsub.subscribe(SUB_EVENTS.M_SLOT, (event, data) => {
            console.log('sub',JSON.stringify(data))
            executeRender(data)
        })
    }, [])

    const handleChangeSegment = target => {
        setSegmentValue(target)
    }

    const handleQuerySlotData = async params => {
        console.log('start to load data:'+new Date().toLocaleTimeString())
        let retData = await querySlotData(params)
        if(!retData['success']) {
            console.log('Get live data error')
            return
        }

        let retDataListWM = retData['result']['WM'].map((item, index) => {
            if (item['propertyList'].length>0 && item['propertyList'].indexOf('WMP') !== -1) {
                item['propertyList'] = ['WM', 'WP']
            }
            return item
        })
        let retDataListWP = retData['result']['WP'].map((item, index) => {
            if (item['propertyList'].length>0 && item['propertyList'].indexOf('WMP') !== -1) {
                item['propertyList'] = ['WM', 'WP']
            }
            return item
        })

        let renderArrWM = handleResponseData('WM', retDataListWM)
        let renderArrWP = handleResponseData('WP', retDataListWP)
        if (renderArrWM.length === 0 && renderArrWP.length===0){
            // equal last response data then reload
            console.log('reloaded data is the same as last time')
            // setTimeout(()=>handleQuerySlotData(params), 3000)
        } else {
            Pubsub.publish(SUB_EVENTS.M_SLOT, {'WM':renderArrWM, 'WP':renderArrWP})
        }
    }

    const handleResponseData = (property, retDataList) => {
        //push to array and save
        let orgJackpotListHistory = JSON.parse(localStorage.getItem('orgJackpotListHistory'+property)) || []

        let localJackpotList = localStorage.getItem('jackpotList'+property)
        console.log('last===curr:'+property, CryptoJS.SHA256(localJackpotList).toString(), CryptoJS.SHA256(JSON.stringify(retDataList)).toString())
        if (!localJackpotList
            || CryptoJS.SHA256(localJackpotList).toString() !== CryptoJS.SHA256(JSON.stringify(retDataList)).toString()) {
            localStorage.setItem('jackpotList'+property, JSON.stringify(retDataList))
            localStorage.setItem('lastJackpotList'+property, JSON.stringify(retDataList))
        } else if (localJackpotList && CryptoJS.SHA256(localJackpotList).toString()
            === CryptoJS.SHA256(JSON.stringify(retDataList)).toString()) {
            return []
        }

        let renderArr = []
        let jackpotList = localStorage.getItem('jackpotList'+property)
        for (let stepNumIdx in Utils.ODOMETER_SLICE_STEP) {
            let stepNum = Utils.ODOMETER_SLICE_STEP[stepNumIdx]
            let localJackpotList = jackpotList && JSON.parse(jackpotList)

            let newDataList = []
            for (let itemDataIdx = 0; itemDataIdx < localJackpotList.length; itemDataIdx++) {
                let itemData = localJackpotList[itemDataIdx]
                for (let bonusIdx in itemData['bonusData']) {
                    let bonusItem = itemData['bonusData'][bonusIdx]
                    for (let bonusItemIndex in bonusItem) {
                        let amount = bonusItem[bonusItemIndex]['amount']
                        if(orgJackpotListHistory.length>0){
                            // history data exist
                            let itemLastAmount = orgJackpotListHistory[orgJackpotListHistory.length-1][itemDataIdx]['bonusData'][bonusIdx][bonusItemIndex]['amount']
                            //if currAmount === last amount,fz directly
                            if(bonusItem[bonusItemIndex]['amount'] == itemLastAmount){
                                bonusItem[bonusItemIndex]['amount'] = itemLastAmount
                            } else {
                                // new data minus last data then split to be 60 fen
                                let portion = (amount -itemLastAmount) / 60
                                bonusItem[bonusItemIndex]['amount'] -= (60-stepNumIdx) * portion

                                // if current amount minus stepNum less than lastAmount
                                // then from last amount
                             /*   if (amount - stepNum > bonusItem[bonusItemIndex]['baseValue']) {
                                    let tmpAmount = bonusItem[bonusItemIndex]['amount'] - stepNum
                                    if(tmpAmount < itemLastAmount || tmpAmount == itemLastAmount){
                                        //todo if less than last value then current value will be show
                                        // bonusItem[bonusItemIndex]['amount'] = amount
                                        bonusItem[bonusItemIndex]['amount'] = itemLastAmount
                                    } else {
                                        bonusItem[bonusItemIndex]['amount'] -= stepNum
                                    }
                                } else {
                                    bonusItem[bonusItemIndex]['amount'] = amount
                                    // bonusItem[bonusItemIndex]['amount'] = bonusItem[bonusItemIndex]['baseValue']
                                }*/
                            }
                        } else {

                            //not exsit history data
                            if (amount - stepNum > bonusItem[bonusItemIndex]['baseValue']) {
                                bonusItem[bonusItemIndex]['amount'] -= stepNum
                            } else {
                                bonusItem[bonusItemIndex]['amount'] = bonusItem[bonusItemIndex]['baseValue']
                            }
                        }
                    }
                }
                newDataList.push(itemData)
            }
            renderArr.push(newDataList)
        }

        if (renderArr.length > 0) {
            orgJackpotListHistory.push(retDataList)
            localStorage.setItem('orgJackpotListHistory'+property, JSON.stringify(orgJackpotListHistory))
            clearTimer()
            localStorage.setItem('renderList'+property, JSON.stringify(renderArr))
        }
        return renderArr
    }

    const handleToDetail = (name, levelList) => {
        clearTimer()
        Utils.checkIsGcm() && wynnRewardsAppAction({
            "wynnId": Utils.getWynnId(),
            "triggerTime": new Date(+new Date()+8*3600*1000).toISOString(),
            "pageVisited": Utils.ACTION_CAPTURE.SJ_SINGLE_HISTORY[0],
            "action": Utils.ACTION_CAPTURE.SJ_SINGLE_HISTORY[1],
            "parameter": JSON.stringify({name:name, levelList: levelList}),
            "itemId": name
        })
        navigate('/mobile/detail', {
            replace: false, state: {
                name:name,
                levelList: levelList
            }
        })
    }

    const [segmentRowClass, setSegmentRowClass] = useState('top-segment-row');
    const [segmentColClass, setSegmentColClass] = useState('top-segment-col');

    const handleAffixChange = state => {
        if (state) {
            setSegmentRowClass('top-segment-row-unfixed');
            setSegmentColClass('top-segment-col-unfixed');
        } else {
            setSegmentRowClass('top-segment-row');
            setSegmentColClass('top-segment-col');
        }
    }

    return (<div className={'slot-container'}>
            <Row>
                <img className={'title-img'} src={require('../../../../public/assets/main/home-title.png')}
                     alt={'home-title'}/>
            </Row>
            {/*<Affix offsetTop={10} onChange={handleAffixChange}>*/}
                <Row className={segmentRowClass}>
                    <Col className={segmentColClass}>
                        <Segmented options={[
                            {label: t('tag.10001'), value: 'WM'},
                            {label: t('tag.10002'), value: 'WP'}
                        ]} size={'large'}
                                   value={segmentValue}
                                   onChange={handleChangeSegment}/>
                    </Col>
                </Row>
            {/*</Affix>*/}

            {
                jackpotRenderListWM && jackpotRenderListWM.map(
                    (item, index) =>
                        <Jackpot key={index} item={item} channel={'mobile'} handleToDetail={handleToDetail}
                                 hideThis={'WP' === segmentValue}/>)
            }
            {
                jackpotRenderListWP && jackpotRenderListWP.map(
                    (item, index) =>
                        <Jackpot key={index} item={item} channel={'mobile'} handleToDetail={handleToDetail}
                                 hideThis={'WM' === segmentValue}/>)
            }
            <Row className={'footer'}>
                <Divider style={{borderTop: '1px solid rgba(255,255,255,0.25)'}}/>
                <Col span={24} className={'text'}>
                    {t('slot.10002')}
                </Col>
            </Row>
        </div>
    )

}


