import React, {useEffect, useState} from 'react';
import {Col, message, Row} from "antd";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {JackpotHis} from '../../../components'
import './index.less';
import {queryLatestTenList} from "../../../../config/api";


/**
 * History
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getHistory()
    }, [])

    const getHistory = async () => {
        let ret = await queryLatestTenList()
        if (ret.success) {
            let dataList = ret['result'].filter(item => item['name'] !== null)

            setDataSource(dataList)
        } else {
            message.error('Network Exception', 5)
        }
    }

    const handleToBack = () => {
        navigate('/web/4C63008D08E93F5A53FD02196488FCA5', {
            replace: false, state: { }
        })
    }

    return (<div className={'web-his-container'}>

            <Row className={'header-row'}>
                <Col span={2} className="arrow-btn">
                        <span onClick={handleToBack}>
                            <img src={require('../../../../public/assets/web/material/Back.png')} alt={'1'}/>
                        </span>
                </Col>
                <Col span={20} className={'top-title-col'}>
                    {t('history.10001')}
                </Col>
            </Row>
            <Row className={'history-bg'} justify="center">
                <Col span={24}>
                    <div className={'history-content'}>
                        <div className="data-view">
                            <div className={'data-wrapper'}>
                                <Row>
                                {dataSource.map((data, index) => (

                                    <JackpotHis itemId={index+1} data={data} />
                                ))}
                                </Row>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    )

}


