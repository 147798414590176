import React, { useState } from 'react';
import CountUp from "react-countup";
import {Odometer} from "odometer_countup";

export default function MyComponent() {
    const [message, setMessage] = useState('Hello, world!');
    const [timerId, setTimerId] = useState(null);

    function handleClick() {
        // 删除定时器
        clearTimeout(timerId);

        // 更新组件状态
        setMessage('定时器已删除');
        setTimerId(null);
    }

    function startTimer() {
        // 创建定时器并将ID存储在状态中
        const id = setTimeout(() => {
            setMessage('Hello, React 18!');
            setTimerId(null);
        }, 1000);

        setTimerId(id);
    }

    return (
        <div>
            <CountUp end={100} duration={1000} />
        </div>
    );
}
