import React, {useEffect, useState} from 'react';
import {Col, message, Row} from "antd";
import {useTranslation} from "react-i18next";
import './index.less';
import {queryLatestTenList} from "../../../../config/api";


/**
 * History
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t, i18n} = useTranslation()
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        getHistory()
    }, [])

    const getHistory = async () => {
        let ret = await queryLatestTenList()
        if (ret.success) {
            let dataList = ret['result'].filter(item => item['name'] !== null)

            setDataSource(dataList)
        } else {
            message.error('Network Exception', 5)
        }
    }

    return (<div className={'his-container'}>
            <Row className="title-wrapper">
                {i18n.language.toUpperCase() ==='EN'
                    ? <Col className={'title'} span={24} >{t('history.10001')}</Col>
                    : <Col className={'title'} span={24} >
                        {t('history.10001')}<br/>{t('history.10002')}
                    </Col>}

            </Row>
            <Row className={'history-bg'} justify="center">
                <Col span={24}>
                    <div className={'history-content'}>
                        <div className="data-view">
                            <div className={'data-wrapper'}>
                                {dataSource.map((data, index) => (
                                    <Row className={'tb-body'} key={data.id}>
                                        <Col span={2} className={'row-item no'}>{index + 1}</Col>
                                        <Col span={9} offset={1} className={'row-item logo'}>
                                            <img
                                                src={require('../../../../public/assets/logo/' + data['name'] + '-logo.png')}
                                                alt={'Image Error'}/>
                                        </Col>
                                        <Col span={12} className={'row-item info'}>
                                            <Row className={'amount'}><Col
                                                span={24}>{data.displayAmount != null && data.displayAmount != '' ? data.displayAmount.substring(3) : ''}</Col></Row>
                                            <Row className={'date'}><Col
                                                span={24}>{data.timestamp != null && data.timestamp != '' ? data.timestamp.substring(0, 10) : ''}</Col></Row>
                                        </Col>
                                    </Row>
                                ))}
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    )

}


