import axiosService from '../axiosService'
import axiosServiceWithoutPrefix from "../axiosServiceWithoutPrefix";

const queryDashboardData = params => axiosService.get(`meterUpdate/queryDashboardData`, params)
const querySlotData = params => axiosService.get(`meterUpdate/querySlotData`, params)
const queryLatestTenList = params => axiosService.get(`jackpotHit/queryLatestTenList`, params)
const wynnRewardsAppAction = params => axiosServiceWithoutPrefix.post(`/wynnCore/api/Logging/WynnRewardsAppAction`, params)

export {
    queryDashboardData,
    querySlotData,
    queryLatestTenList,
    wynnRewardsAppAction
}
