import React, {useEffect, useState} from 'react';
import {Col, Row, Segmented, Select, Affix, message, Button} from "antd";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './index.less';
import store from "store";
import {queryLatestTenList} from "../../../../config/api";


/**
 *
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {state: {name, levelList}} = useLocation()
    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        handleQueryJackpotLatestTen({levelList:levelList})
    }, [])

    const handleQueryJackpotLatestTen = async params => {
        let ret = await queryLatestTenList(params)
        console.log(ret)
        if (ret.success) {
            setDataSource(ret.result)
        } else {
            message.error('接口請求異常', 5)
        }
    }

    const handleToSlot = () =>{
        navigate('/web/4C63008D08E93F5A53FD02196488FCA5', {
            replace: false, state: {}
        })
    }

    const bgImage = require("../../../../public/assets/web/detail/"+name+".png")

    return (<div className={'web-detail-container'}
                 style={{backgroundImage:`url(${bgImage})`}}>
            <Row className={'btn-area'}>
                <Col offset={1} span={4}>
                    <span className={'icon-back-btn'} onClick={handleToSlot}></span>
                </Col>
            </Row>
            <div className="data-view">
                <div className={'data-wrapper'}>
                    <Row>
                        <Col span={8} offset={4} className={'left-block'}>
                            <Row className={'title'}>
                                <Col span={24}>{t('detail.10001')}</Col>
                            </Row>
                            <Row>
                                <Col span={24} className={'logo'}>
                                    <img src={require('../../../../public/assets/web/logo/'+name+'-logo.png')} alt={'.'} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6} offset={1} className={'data-area'}>
                            <Row className={'tb-header'}>
                                <Col span={8} offset={4}>{t('detail.10002')}</Col>
                                <Col span={12} style={{textAlign:'right'}}>{t('detail.10003')}</Col>
                            </Row>
                            {dataSource.map((data, index) => (
                                <Row className={'tb-body'} key={index}>
                                    <Col span={2} className={'no'}>{ index + 1}</Col>
                                    <Col span={8} offset={2} className={'date'}>{data.timestamp!=null&&data.timestamp!=''?data.timestamp.substring(0, 10):''}</Col>
                                    <Col span={12} className={'amount'}>{data.displayAmount!=null&&data.displayAmount!=''?data.displayAmount.substring(3):''}</Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )

}


