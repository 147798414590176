import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import store from 'store'
import enLang from './en.json'
import scLang from './sc.json'
import tcLang from './tc.json'
import Utils from "../../src/util";

const urlParams = Utils.getSearchParams();
if (JSON.stringify(urlParams) !== '{}') {
    localStorage.setItem('searchParams', JSON.stringify(urlParams))
}
const searchParams = store.get('searchParams')
const lang = searchParams && searchParams['xLang'] || 'TC'

const resources = {
    EN: {
        translation: enLang,
    },
    SC: {
        translation: scLang,
    },
    TC: {
        translation: tcLang,
    }
}
i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
