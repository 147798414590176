import React, {useEffect} from 'react';
import {Col, Row} from 'antd'
import {useTranslation} from "react-i18next";
import Utils from "../../../src/util";
import './index.less'
import Odometer from "react-odometerjs";

export default function Index(props) {

    const {t, i18n} = useTranslation()

    const {name, level, propertyList, bonusData, hasHis, levelList} = props.item

    const handleToDetail = () => {
        props.handleToDetail(name, levelList.toString())
    }
    return (
        <Col span={props.channel==='web'?12:24} className={'jackpot-container '+ (props.hideThis ? 'hidden':'')} >
            <Row className="logo-wrapper">
                <Col span={16} offset={4}>
                    <img src={require('../../../public/assets/logo/' + name + '-logo.png')} alt={1}/>
                </Col>
            </Row>
            <Row className={'property-wrapper'}>
                <Col span={16} offset={4}>
                    <Row>
                        {propertyList && propertyList.map((item, index) =>
                            <Col key={index} span={propertyList.length > 1 ? 12 : 24}>
                                <div className={'property'}>{
                                    item === 'WM' ? t('tag.10001') : (item === 'WP' ? t('tag.10002') : '')
                                }</div>
                            </Col>)}
                    </Row>
                </Col>
                <Col span={4}>
                    <div className="arrow-btn" style={{display:hasHis?'':'none'}}>
                        <span onClick={handleToDetail}>
                            <img src={require('../../../public/assets/material/Arrow.png')} alt={'1'}/>
                        </span>
                    </div>
                </Col>
            </Row>
            {
                bonusData && Object.entries(bonusData).map(([key, value]) =>value.length === 2 ?
                    <Row className={'slot slot-block'} key={key}>
                        <Col span={24} >
                            <Row className={'bit-wrapper'}>
                                <Col span={24} className={'amount-bit'}> <span className={'bit'}>{key.replaceAll(' ', ',')}</span> </Col>
                            </Row>
                        </Col>
                        <Col span={24} >
                            <Row className={'live-row'}>
                                <Col span={5} className={'type-wrapper'}>
                                    <span>{t('tag.10003')}</span>
                                </Col>
                                <Col span={18} offset={1} className={'amount'}>
                                    <div className={'data-wrapper'}>
                                        <div className={'value'}>
                                            <span>$</span>
                                            <Odometer value={(value[0]['amount']/100+0.001).toFixed(3)} duration={Utils.ODOMETER_DURATION} format='(,ddd).ddd' animation={'slot'}
                                                      theme={'default'}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'live-row'}>
                                <Col span={5} className={'type-wrapper'}>
                                    <span>{t('tag.10004')}</span>
                                </Col>
                                <Col span={18} offset={1} className={'amount'}>
                                    <div className={'data-wrapper'}>
                                        <div className={'value'}>
                                            <span>$</span>
                                            <Odometer value={(value[1]['amount']/100+0.001).toFixed(3)} duration={120000} format='(,ddd).ddd' animation={'slot'}
                                                      theme={'default'}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className={Object.keys(bonusData).pop() === key ? '':'link-bar-wrapper' }>
                            <Row>
                                <Col span={2} offset={2} className={'link-bar'}></Col>
                                <Col span={2} offset={18} className={'link-bar'}></Col>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <Row className={'slot single-slot-block'} key={key}>
                        <Col span={24} >
                            <Row className={'bit-wrapper'}>
                                <Col span={24} className={'amount-bit'}> <span className={'bit'}>{key.replaceAll(' ', ',')}</span> </Col>
                            </Row>
                        </Col>
                        <Col span={24} >
                            <Row className={'live-row'}>
                                <Col span={5} className={'type-wrapper'}>
                                    <span>{value[0]['type'].toUpperCase() === 'GRAND'? t('tag.10003'):t('tag.10004')}</span>
                                </Col>
                                <Col span={18} offset={1} className={'amount'}>
                                    <div className={'data-wrapper'}>
                                        <div className={'value'}>
                                            <span>$</span>
                                            <Odometer value={(value[0]['amount']/100+0.001).toFixed(3)} duration={19000} format="(,ddd).ddd" animation={'slot'}
                                                      theme={'default'}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} className={Object.keys(bonusData).pop() === key ? '':'link-bar-wrapper' }>
                            <Row>
                                <Col span={2} offset={2} className={'link-bar'}></Col>
                                <Col span={2} offset={18} className={'link-bar'}></Col>
                            </Row>
                        </Col>
                    </Row>

                )
            }

        </Col>
    )
}


